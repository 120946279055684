.login-container {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  min-height: 400px;
  align-items: center;
  margin-top: 55px;
}

.login-form-between-padding {
  padding-top: 20px;
}

.platform-login .p-button {
  margin-right: 0.5rem;
}

.App {
  font-family: sans-serif;
}
